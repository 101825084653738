import React from 'react';
import ReactPaginate from 'react-paginate';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import './App.scss';

// pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

class RechercheForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			query: {},
			items: [],
			total_items: null,
			preview_item: null,
			items_per_page: null,
			all_themes: [],
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onPreviewItem = this.onPreviewItem.bind(this);
		this.onItemOffset = this.onItemOffset.bind(this);
	}

	handleChange(event) {
		let new_query = Object.assign({}, this.state.query, {[event.target.name]: event.target.value});
		this.fetchResults(new_query);
	}

	componentDidMount() {
		// Récupération de la liste des thèmes
		fetch(new URL('/api/v1/theme', window.location))
			.then(res => res.json())
			.then(
				(result) => this.setState({all_themes: result})
			);
	}

	fetchResults(query) {
		let url = new URL('/api/v1/sujet/search', window.location);
		for(const [key, value] of Object.entries(query)) {
			if(value) {
				url.searchParams.append(key, value);
			}
		}
		fetch(url)
			.then(res => res.json())
		  .then(
				(result) => this.setState({
					query: query,
					items: result.items,
					items_per_page: result.items_per_page,
					total_items: result.count
				})
			);
	}

	handleSubmit(event) {
		let new_query = Object.assign({}, this.state.query, {start: 0});
		this.fetchResults(new_query);
		event.preventDefault();
	}

	onPreviewItem(item) {
		this.setState({preview_item: item});
	}

	closePreview() {
		this.setState({preview_item: null});
	}

	onItemOffset(item) {
		// Soumettre la requête à nouveau avec l'offset sur la liste de résultats
		let new_query = Object.assign({}, this.state.query, {start: item});
		this.fetchResults(new_query);
	}

	render () {
		let pageCount = 0;
		if(this.state.items_per_page) {
			pageCount = Math.ceil(this.state.total_items / this.state.items_per_page);
		}

		return (<div className="search">
			<form onSubmit={this.handleSubmit}>
			<p className="fulltext"><input type="text" name="q" value={this.state.value} placeholder="Texte à rechercher…" onChange={this.handleChange}/></p>
			<p><label>Année minimum : <input type="number" name="annee_min" onChange={this.handleChange} />
			</label>
			<label>Année maximum : <input type="number" name="annee_max" onChange={this.handleChange} />
			</label></p>
			<p><label>Voie :</label>
			<MoteurList field="voie" onChange={this.handleChange} /></p>
			<p><label>École :</label>
			<MoteurList field="ecole" onChange={this.handleChange} /></p>
			<p><input type="submit" id="sujet-submit" value="Rechercher"/></p>
			</form>

			<RechercheResultList
				items={this.state.items}
				onPreviewItem={this.onPreviewItem}
				onItemOffset={this.onItemOffset}
				itemsPerPage={this.state.items_per_page}
				pageCount={pageCount}
				totalItems={this.state.total_items}
				allThemes={this.state.all_themes}
			/>
			<DocumentPreview {...this.state.preview_item} closePreview={this.closePreview.bind(this)}/>
			</div>
		);
	}
}

class MoteurList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.onChange = this.onChange.bind(this);
	}

	componentDidMount() {
		// Récupération des choix possibles pour les champs de recherche
		fetch(new URL('/api/v1/' + this.props.field, window.location))
			.then(res => res.json())
			.then(
				(result) => result.forEach((item, index) => this.setState({[item] : true}))
			);
	}

	onChange(event) {
		let new_state = {[event.target.value]: event.target.checked};
		this.setState(new_state);
		let checked = [];
		for(const [key, value] of Object.entries(Object.assign({}, this.state, new_state))) {
			if(value) {
				checked.push(key);
			}
		}
		this.props.onChange({
			target: {
				name: this.props.field,
				value: checked.join()
			}
		});
	}

	render() {
		let res = [];
		for(const [key, value] of Object.entries(this.state)) {
			res.push(<label><input type="checkbox" name={this.props.value} defaultChecked={value} value={key} onChange={this.onChange}/> {key}</label>);
		}
		return res;
	}
}

class RechercheResult extends React.Component {
	render() {
		let enonces = [];
		let corriges = [];
		for(const fichier of this.props.fichiers) {
			let preview;
			if(fichier.format === 'pdf') {
				preview = <button className="toggle-preview" onClick={() => this.props.onPreviewItem(fichier)}>(aperçu)</button>;
			}
			let ligne = (<li><a href={fichier.href}>{fichier.format}</a> {preview}</li>);

			if(fichier.doctype === "enonce") {
				enonces.push(ligne);
			}
			if(fichier.doctype === "corrige") {
				corriges.push(ligne);
			}
		}
		let themes = [];
		for(const theme of this.props.themes) {
			themes.push(<li>{this.props.allThemes[theme].name}</li>);
		}

		return (<li>
			<p className="result-title"><span className="ecole">{this.props.ecole}</span> <span className="epreuve">{this.props.epreuve}</span> <span className="annee">{this.props.annee}</span>, <span className="voie">voie {this.props.voie}</span></p>
			<div className="result-details">
			<ul className="parties">
			{this.props.parties.map((value, index) => <li><strong className="part-name">{value.partie}</strong> : {value.resume ?? <span className="not-available">(non renseigné)</span>}</li>)}
			</ul>

			<div className="result-files">
			Énoncés : <ul className="enonces">{enonces}</ul>
			</div>

			<div className="result-files">
			Corrigés : <ul className="corriges">{corriges}</ul>
			</div>

			<div className="result-themes">
			Thèmes : <ul className="themes">{themes}</ul>
			</div>

			</div>

			</li>);
	}
}

function ResultatResume(props) {
	if(props.totalItems === null) {
		return "";
	}
	else {
		if(props.totalItems === 0) {
			return (<p>Après avoir consulté avec grande attention la base des sujets, je n'ai trouvé aucun sujet pour votre recherche.</p>);
		}
		else {
			if(props.totalItems === 1) {
				return (<p>Après avoir consulté avec grande attention la base des sujets, je n'ai trouvé qu'un seul sujet pour votre recherche.</p>);
			}
			else {
				return (<p>Après avoir consulté avec grande attention la base des sujets, j'ai trouvé {props.totalItems} résultats.</p>);
			}
		}
	}
}

class RechercheResultList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handlePageClick(event) {
		this.setState({ current_page: event.selected });
		this.props.onItemOffset(event.selected * this.props.itemsPerPage);
	}

	componentDidUpdate() {
		if(this.state.current_page >= 0) {
		  window.scrollTo({top: 0, left: 0, behavior: "smooth"});
			document.getElementsByClassName('result-list')[0].style.counterReset = 'searchResults ' + this.state.current_page * this.props.itemsPerPage;
		}
	}

	render() {
		return (<>
			<ResultatResume totalItems={this.props.totalItems}/>
			<ol className="result-list">
			{this.props.items.map((value, index) => <RechercheResult {...value} onPreviewItem={this.props.onPreviewItem} allThemes={this.props.allThemes} />)}
			</ol>
			<ReactPaginate
				className="result-paginate"
				breakLabel="⋯"
				nextLabel="suivant"
				onPageChange={this.handlePageClick.bind(this)}
				pageRangeDisplayed={5}
				pageCount={this.props.pageCount}
				previousLabel="précédent"
				renderOnZeroPageCount={null}
			/>
			</>
		);
	}
}

class DocumentPreview extends React.Component {
	constructor(props) {
		super(props);
		this.state = { docLoaded: false, currentPage: 1, totalPages: null };
		this.onPrecPage = this.onPrecPage.bind(this);
		this.onNextPage = this.onNextPage.bind(this);
		this.onLoadSuccess = this.onLoadSuccess.bind(this);
	}

	onLoadSuccess({ numPages }) {
		this.setState({ totalPages: numPages, currentPage: 1, docLoaded: true });
	}

	onPrecPage() {
		if(this.state.docLoaded && this.state.currentPage > 1) {
			this.setState({ currentPage: this.state.currentPage - 1});
		}
	}

	onNextPage(event) {
		if(this.state.docLoaded && this.state.currentPage < this.state.totalPages) {
			this.setState({ currentPage: this.state.currentPage + 1});
		}
	}

	onClosePage() {
	}

	render() {
		if(this.props.href) {
			let url = new URL(this.props.href, window.location);
			url = url.toString();
			return (
				<div id="preview">
					<p className="controls">
						<button className="pdf-close" onClick={this.props.closePreview}>fermer</button>
						<button className="pdf-prev" onClick={this.onPrecPage} disabled={!this.state.docLoaded || this.state.currentPage <= 1 ? "disabled" : null}>prec</button>
						<button className="pdf-next" onClick={this.onNextPage} disabled={!this.state.docLoaded || this.state.currentPage >= this.state.totalPages ? "disabled": null}>suiv</button>
						<span className="pdf-page-counter">Page <span className="pdf-page-current">{this.state.currentPage}</span> sur <span className="pdf-page-total">{this.state.totalPages}</span></span>
					</p>
					<Document file={url} onLoadSuccess={this.onLoadSuccess}>
					<Page pageNumber={this.state.currentPage}/>
					</Document>
				</div>
			);
		}
		else {
			return <div></div>;
		}
	}
}

function App() {
  return (
		<div className="App">
		<RechercheForm/>
    </div>
  );
}

export default App;
